const showNewsletterBanner = () => {
  $('#newsletter-banner').removeClass('dn');
};

const hideNewsletterBanner = () => {
  $('#newsletter-banner').addClass('dn');
};

const showNewsletterForm = () => {
  hideNewsletterBanner();
  $('#newsletter-form').removeClass('dn');

  window.dataLayer.push({
    event: 'newsletter_popup_form_show'
  });
};

const hideNewsletterForm = () => {
  $('#newsletter-form').addClass('dn');
};

const shouldDisplayBanner = () => {
  if (!$('#newsletter-banner').length) return false;

  // Display the newsletter banner if the user has specified newsletter_banner as a param in the URL - This
  // makes it easy to test the banner and the newsletter form
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('newsletter_banner') === '1') return true;
  if (urlParams.get('black_friday_banner') === '1') return true;
  if (urlParams.get('christmas_banner') === '1') return true;

  const bannerType = $('#newsletter-banner').data('banner-type');

  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.indexOf(`${bannerType}=1`) === 0) {
      return false;
    }
  }

  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 30);
  const cookieValue = `1; expires=${expirationDate.toUTCString()}'; path=/`;
  document.cookie = `${bannerType}=${cookieValue}`;

  return true;

  // Previous version:
  // Display banner if the user has viewed elegible pages this many times
  // const VIEW_COUNT = 1;

  // const oldViewCount = parseInt(window.localStorage.getItem('viewCount'), 10) || 0;

  // // Already shown the banner
  // if (oldViewCount === VIEW_COUNT) return false;

  // // Increment
  // const newViewCount = oldViewCount + 1;
  // window.localStorage.setItem('viewCount', newViewCount);
  // return newViewCount === VIEW_COUNT;
};

const triggerBannerShownGTMEvent = () => {
  window.dataLayer.push({
    event: 'newsletter_banner_popup'
  });
};

$(() => {
  $('#newsletter-banner-close-icon').on('click', hideNewsletterBanner);
  $('#newsletter-banner-button').on('click', showNewsletterForm);
  $('.js-newsletter-close').on('click', hideNewsletterForm);
  if (shouldDisplayBanner()) {
    showNewsletterBanner();
    triggerBannerShownGTMEvent();
  }
});

$('body').on('click', (e) => {
  if (e.target.classList.contains('modal-overlay')) hideNewsletterForm();
});
